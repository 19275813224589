import pima from '../pima-api'
import {useQuery} from "react-query";
import { useNavigate } from "react-router-dom";
import { Hero, TwoImages, Journal, TxtBlock, FullBleed } from '../components/homeBlocks/blocks'
import {useEffect, useRef, useState, useMemo} from "react";
import {A, CtaA, BlockA, BorderlessInput} from "../components/styledComponents";
import appStyles from "../scss/App.module.scss"
import wstyles from "../scss/Welcome.module.scss"

export default ({}) => {
  let navigate = useNavigate()

  const getHomeData = () => {
    fetch( 'https://checkout.buckmason.com/pages/api-rms', {method: 'GET'})
    .then( response => response.json() )
    .then(data => {
      setHomeTrack(data.tracking.blocks)
    })
    .catch(err => console.error(err));
  }
  const [homeTrack, setHomeTrack] = useState([])
  const homeData = useMemo(()=> getHomeData(), [])

  const {isLoading: loadingLocations, data: locations} = useQuery(['locations'], pima.getLocations)
  const [coords, setCoords] = useState()
  const [sortedLocations, setSortedLocations] = useState()

  useEffect(()=>{
    if(locations && coords) {
      getDistances(coords)
    }
  }, [locations, coords])

  const [zip, setZip] = useState()
  const onZipChange = e =>{
    const value = e.target.value
    setZip(value)

    if(value.length === 5) {
      getZipCoords(value)
    }
  }

  const onKeyPress = e => {
    if(e.keyCode === 13) getZipCoords(zip)
  }

  const [zipError, setZipError] = useState()

  const [loadingZipCoords, setLoadingZipCoords] = useState()
  const getZipCoords = zip =>{
    setLoadingZipCoords(true)
    setZipError(false)

    new window.google.maps.Geocoder().geocode( { 'address': zip}, function(res, status){
      setLoadingZipCoords(false)

      if(!res) {
        setZipError(true)
        return
      }

      let coords = {
        latitude: res[0].geometry.location.lat(),
        longitude: res[0].geometry.location.lng()
      }

      setCoords(coords);

      getDistances(coords)
    });
  }

  const getDistances = coords =>{
    if(!locations) return;

    const origin = new window.google.maps.LatLng(coords.latitude, coords.longitude);

    for ( let location of locations ) {
      const dest = new window.google.maps.LatLng(location.coordinates[0], location.coordinates[1]);
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(origin, dest);
      let raw_dist = (distance * 0.000621371).toFixed(2);
      location.distance = raw_dist > 100 ? Math.round(raw_dist) : raw_dist;
    }

    let loc_list = [...locations.sort(function(a,b){ return a.distance - b.distance })]
    loc_list = loc_list.filter(x => !!x.name && !x.name.toLowerCase().includes('bloomingdale'))

    setSortedLocations(loc_list)
  }

  const inputRef = useRef()

  useEffect(()=>{
    inputRef.current?.focus()
  }, [inputRef.current])

  const [browserPosition, setBrowserPosition] = useState()

  useEffect(()=>{
    if(browserPosition || browserPosition === false) return;

    navigator.geolocation.getCurrentPosition(position => {
      setBrowserPosition(position.coords)
      setCoords(position.coords)
      getDistances(position.coords)
    }, (e)=>{
      console.log('Browser Location Denied', e);
      setBrowserPosition(false)
    }, {
      enableHighAccuracy: false,
      timeout: 30000,
      maximumAge: 0
    });
  }, [])

  return (
    <>
      <div className={appStyles.appwr}>
        <div>
          <h1 className='view_title'>Return to Store</h1>

          <div className='top_search'>
            <BorderlessInput 
              type={'text'} 
              onChange={onZipChange} 
              autoComplete={'off'} 
              name={'Zip Code'} 
              ref={inputRef} 
              onKeyPress={onKeyPress} 
              placeholder={'Enter zip code to find your store'}
              aria-label={'Enter zip code to find your store'}
              style={{textAlign: 'left', paddingLeft: '0'}}
            />
            <button 
              className='aria_btn store_btn' 
              aria-label={'Submit'}
            ></button>

            
            {loadingLocations && <div className='top_search-status'><p>Loading...</p></div>}
            {loadingZipCoords && <div className='top_search-status'><p>Loading...</p></div>}
            {zipError && <div className='top_search-status'><p>Sorry, we couldn't find that zip code, please try another.</p></div>}

          </div>
        </div>

        <div>
          OR
          <CtaA href={'/start-return'} style={{marginLeft: '1.5em'}}>Start a return by mail</CtaA>
        </div>

        {sortedLocations && (
          <div style={{marginTop:'4em'}}>
            {sortedLocations.filter(loc => loc.distance < 100).map((location,index) => {

              return (
                <Location key={location.short_name} location={location} index={index}/>
              )
            })}
          </div>
        )}
      </div>

      <div className={wstyles.home_blocks} style={{borderTop: 0}}>
        {homeTrack.map((block, index) => {
          switch (block.type) {
            case 'hero':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'hero_wide':
              return <Hero key={`block${index}`} block={block} index={index} />;
            case 'two_img':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'two_off':
              return <TwoImages key={`block${index}`} block={block} index={index} />;
            case 'text':
              return <TxtBlock key={`block${index}`} block={block} index={index} />;
            case 'journal':
              return <Journal key={`block${index}`} block={block} index={index} />;
            case 'full_bleed':
              return <FullBleed key={`block${index}`} block={block} index={index} />;
            default:
              return ''
          }
        })}
      </div>
    </>
  )
}

const Location = ({location, index})=>{
  const [showDetails, setShowDetails] = useState()

  const address = `${location.address_line_1}, ${location.address_line_2}, ${location.address_city}, ${location.address_state}, ${location.address_zip}`
  const ariaid = `ar-result_detail_${index}`

  return (
    <div className='result'>
      <A 
        onClick={()=> setShowDetails(!showDetails)} 
        aria-expanded={showDetails ? 'true' : 'false'}
        aria-controls={ariaid}
        style={{display:'flex'}}
      >
        <div>
          <strong>{location.name}</strong> {location.distance && <>({location.distance}mi away)</>}
        </div>

        <div style={{marginLeft:'auto'}}>
          <div 
            
          >
            Hours + Details
          </div>
        </div>
      </A>

      {showDetails && (
        <div id={ariaid} className='result_detail' role="region">
          <div>
            <strong>Hours:</strong>
            <br/>
            <div style={{whiteSpace: 'pre-line'}}>{location.hours}</div>
          </div>
          <div>
            <strong>Address:</strong>
            <br/>
            <a href={`https://maps.google.com?q=${address}`} target={'_blank'} rel='noreferrer noopener nofollow'>{address}</a>
          </div>
          <div>
            <strong>Phone:</strong>
            <br/>
            <a href={`tel://${location.phone}`} target={'_blank'} rel='noreferrer noopener nofollow'>{location.phone}</a>
          </div>
        </div>
      )}
    </div>
  )
}